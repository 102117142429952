import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    loadingState: true,
    emptyState: false,
  },
  mutations: {
    showState(state) {
      state.loadingState = false;
      state.emptyState = true;
    },
  },
});

